<template>
 <card padding="0" class="flex justify-center items-center w-full bg-cover bg-repeat-round" @click="click" :style="{ height }">
   <iframe class="rounded-xl" :width="isMobile ? 420 : 500" height="118" src="https://www.youtube.com/embed/xVVoWBcM4_Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
   <!-- <b-icon icon="play-circle-outline" size="is-large" class="text-white"></b-icon> -->
   <!-- <img :src="thumb" class="VideoCard-img w-full" :height="height" /> -->
  </card>
</template>

<script>
import Card from './Card'
export default {
  components: {
    Card
  },
  props: {
    thumb: String,
    video: String
  },
  computed: {
    height () {
      return '120px'
    }
  },
  methods: {
    click () {
      if (this.video) window.open(this.video, this.isCordova ? '_system' : '_blank')
    }
  }
}
</script>

<style scoped>
  .VideoCard-img {
    border-radius: inherit;
  }
</style>
